@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


@import "~bootstrap/scss/grid";

@import "~bootstrap/scss/utilities";

//@import "./src/assets/scss/shop";

@import './src/assets/scss/variables';
@import './src/assets/scss/mixin';


/*===============================
    28.SHOP css
================================*/


.product{
  & .shop-sidebar-area{
    padding: 33px 40px 30px;
    @media #{$lg} {
      padding: 33px 20px 30px;
    }
    & .sidebar-title{
      & .title{
        font-size: 20px;
        position: relative;
        &::before{
          height: 4px;
          width: 4px;
          position: absolute;
          content: '';
          background: $theme-color;
          left: 30px;
          top: 12px;
        }
        &::after{
          height: 4px;
          width: 18px;
          position: absolute;
          content: '';
          background: $theme-color;
          left: 8px;
          top: 12px;
        }
      }
    }
    & .sidebar-list{
      padding-top: 17px;
      & ul{
        & li{
          & a{
            color: #75a1af;
            font-size: 14px;
            line-height: 35px;
            @include transition(0.3s);
            & i{
              padding-right: 15px;
            }
            &:hover{
              color: $heading-color;
            }
          }

        }
      }
    }
    & .sidebar-tag{
      padding-top: 21px;
      & ul{
        & li{
          display: inline-block;
          & a{
            color: $theme-color;
            text-decoration: underline;
            margin: 0 8px;
            font-size: 14px;
            font-weight: 700;
            padding-top: 3px;
            padding-bottom: 5px;
            @media #{$lg} {
              margin: 0 5px;
            }
          }
        }
      }
    }
    & .sidebar-subscribe{
      & p{
        font-size: 14px;
        line-height: 24px;
        padding-top: 18px;
        padding-bottom: 15px;
      }
      & .input-box{
        & input{
          width: 100%;
          line-height: 50px;
          padding-left: 30px;
          border: 2px solid #edf1ff;
          color: #7585af;
          font-size: 14px;
          &::placeholder{
            opacity: 1;
            color: #7585af;
            font-size: 14px;
          }
        }
        & button{
          padding: 0;
          width: 100%;
          line-height: 50px;
          border-radius: 0;
          margin-top: -2px;
          margin-bottom: 10px;
          background: $theme-color;
          border-color: $theme-color;
          font-size: 14px;
        }
      }
    }

  }
  & .shop-top-bar{
    position: relative;
    & .input-box{
      position: relative;
      display: inline-block;
      margin-left: 120px;
      @media #{$lg} {
        margin-left: 0;
      }
      @media #{$md} {
        margin-left: 0;
      }
      @media #{$xs} {
        margin-left: 0;
      }
      & input{
        line-height: 60px;
        border: 0;
        border-radius: 30px;
        width: 270px;
        padding-left: 30px;
        color: #94aebf;
      }
      & button{
        position: absolute;
        top: 0;
        right: 0;
        height: 60px;
        width: 60px;
        border-radius: 50%;
        background: #fff;
        border: 0;
        color: $theme-color;

      }
    }
    & .nice-select{
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 160px;
      border: 0;
      line-height: 60px;
      height: auto;
      border-radius: 30px;
      padding-left: 35px;
      color: #42495b;
      font-weight: 700;
      font-size: 14px;
      z-index: 99;
      @media #{$xs} {
        left: 0;
        right: auto;
        top: -50px;
      }
      @media #{$sm} {
        right: 0;
        left: auto;
        top: 50%;
      }
      &::after{
        width: 8px;
        height: 8px;
        right: 40px;
        border-bottom: 2px solid $theme-color;
        border-right: 2px solid $theme-color;
      }
    }
    & p{
      color: #002249;
      font-weight: 700;
      @media #{$xs} {
        padding-top: 30px;
      }
    }
  }
  & .shop-item{
    overflow: hidden;
    & .shop-thumb{
      position: relative;
      & img{
        width: 100%;
      }
      & .shop-overlay{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 40, 72, 0.6);
        opacity: 0;
        @include transition(0.5s);
        & ul{
          position: absolute;
          left: 50%;
          top: 50%;
          @include transform(translate(-50%, -0%));
          white-space: nowrap;
          @include transition(0.5s);
          opacity: 0;
          & li{
            display: inline-block;
            & a{
              height: 50px;
              width: 50px;
              border-radius: 50%;
              text-align: center;
              line-height: 50px;
              background: $white;
              margin: 0 5px;
              @include transition(0.3s);
              &:hover{
                color: $white;
                background: $theme-color;
              }
            }
          }
        }
      }
    }
    & .shop-content{
      padding: 25px 0 25px 15px;

      & .title{
        font-size: 18px;
        @media #{$lg} {
          font-size: 17px;
        }
      }
      & ul{
        padding-top: 5px;
        & li{
          display: inline-block;
          margin: 0 8px;
          &:first-child{
            text-decoration: line-through;
            font-size: 14px;
            color: #b2c0d3;
          }
          &:last-child{
            font-weight: 700;
            color: $theme-color;
            font-size: 14px;
          }
        }
      }
    }
    &:hover{
      & .shop-thumb{
        & .shop-overlay{
          opacity: 1;
          & ul{
            opacity: 1;
            @include transform(translate(-50%, -50%));
          }
        }
      }

    }
  }



}





.product{
  background: #f4fbff;
  padding-top: 100px;
  padding-bottom: 200px;


}

.product-group{
  @extend .col-lg-4;
  @extend .col-md-4;
  @extend .col-sm-6;

  .product-content{
    // @extend .shop-item;
    margin-top: 30px;
    overflow: hidden;


    & .shop-thumb{
      position: relative;
      & img{
        width: 100%;
      }
      & .shop-overlay{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 40, 72, 0.6);
        opacity: 0;
        @include transition(0.5s);
        & ul{
          position: absolute;
          left: 50%;
          top: 50%;
          @include transform(translate(-50%, -0%));
          white-space: nowrap;
          @include transition(0.5s);
          opacity: 0;
          & li{
            display: inline-block;
            & a{
              height: 50px;
              width: 50px;
              border-radius: 50%;
              text-align: center;
              line-height: 50px;
              background: $white;
              margin: 0 5px;
              @include transition(0.3s);
              &:hover{
                color: $white;
                background: $theme-color;
              }
            }
          }
        }
      }
    }



    & .product-price{
      z-index: 9;
      background-color: #0072FF;
      color: $white;
      display: block;
      height: 54px;
      width: 54px;
      line-height: 54px;
      border-radius: 2px;
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      position: absolute;
      right: 10%;
      bottom: -15%;
      opacity: 0.9;

      @extend .float-left;
      @extend .text-center;
      //  @extend .d-flex;
      //  @extend .flex-row-reverse;


      // @extend .d-flex;
      // @extend .flex-wrap;

      //   @extend .align-content-center;

    }





    & .product-info{
      padding: 25px 15px 25px 15px;
      background-color: white;


      & .title{
        text-decoration: none;
        // border: 1px solid red;
        & h5{
          font-size: 15px;
          margin-bottom: 0;
        }
        @media #{$lg} {
          font-size: 17px;
        }
      }
      & .vendor-info{
        // border: 1px solid red;
        margin-bottom: 8px;
        & a{
          display: inline;
        }
      }
      & ul{
        padding-top: 5px;
        & li{
          display: inline-block;
          margin: 0 8px;
          &:first-child{
            text-decoration: line-through;
            font-size: 14px;
            color: #b2c0d3;
          }
          &:last-child{
            font-weight: 700;
            color: $theme-color;
            font-size: 14px;
          }
        }
      }
    }
    &:hover{
      & .shop-thumb{
        & .shop-overlay{
          opacity: 1;
          & ul{
            opacity: 1;
            @include transform(translate(-50%, -50%));
          }
        }
      }

    }





    & .footer{
      //border: 1px solid green;
      margin-left: -15px;
      margin-right: -15px;
      margin-bottom: -25px;
      @extend .d-flex;

      & a{
        padding: 7px;
        text-decoration: none;
        color: $white;
        background-color: $primary;
        @extend .text-center;
        @extend  .flex-fill;

        & svg{
          padding-right: 5px;
        }
      }


      & a:nth-child(2){
        background-color: #0072FF;
      }



      // @extend .justify-content-between;
    }












  }
}

