/*===========================
    12.ABOUT css 
===========================*/

.about-area {
	& .about-thumb {
		position: relative;
		z-index : 5;

		&::before {
			position  : absolute;
			content   : '';
			height    : 630px;
			width     : 630px;
			border    : 120px solid $theme-color;
			transform : rotate(45deg);
			background: $white;
			z-index   : -1;
			left      : -200px;
			top       : 40px;

			@media #{$lg} {
				height: 500px;
				width : 500px;
			}

			@media #{$md} {
				height: 500px;
				width : 500px;
				top   : 85px;
			}

			@media #{$xs} {
				display: none;
			}
		}
	}

	& .about-item {
		padding-left: 30px;
		position    : relative;

		@media #{$xs} {
			padding-left: 0;
		}

		&::before {
			position     : absolute;
			content      : '';
			width        : 0;
			height       : 0;
			border-bottom: 30px solid $theme-color;
			border-left  : 30px solid transparent;
			right        : 17%;
			bottom       : -50px;

			@media #{$xs} {
				right : 1rem;
				bottom: -1rem;
			}

			@media #{$sm} {
				right : 0;
				bottom: 0;
			}

			@media #{$lg} {
				right : 10%;
				bottom: -35px;
			}
		}

		&>span {
			color         : #2385C5;
			font-weight   : 700;
			letter-spacing: 2px;
			text-transform: capitalize;
		}

		& .title {
			font-size     : 43px;
			line-height   : 43px;
			padding-top   : 18px;
			color         : #0072FF;
			padding-bottom: 36px;

			@media #{$xs} {
				font-size  : 28px;
				line-height: 32px;
			}

			@media #{$sm} {
				font-size  : 50px;
				line-height: 60px;
			}
		}

		& p {
			color        : #333333;
			margin-bottom: 20px;
		}

		& .about-experience {
			display      : flex;
			align-items  : center;
			margin-top   : 10px;
			margin-bottom: 28px;

			@media #{$xs} {
				margin-bottom: 0px;
			}

			@media #{$sm} {
				margin-bottom: 0px;
			}

			& h3 {
				font-weight   : 700;
				color         : $theme-color;
				font-size     : 0px;
				font-family   : $gilroy;
				padding-right : 15px;
				position      : relative;
				padding-bottom: 40px;

				&::before {
					position    : absolute;
					content     : '';
					width       : 0;
					height      : 0;
					border-top  : 30px solid $theme-color;
					border-right: 30px solid transparent;
					left        : 0;
					top         : 23px;
				}
			}

			& span {
				font-size     : 30px;
				line-height   : 40px;
				font-family   : $gilroy;
				font-weight   : 700;
				color         : $heading-color;
				text-transform: capitalize;

				@media #{$xs} {
					font-size: 26px;
				}

				@media #{$sm} {
					font-size: 30px;
				}

			}
		}

		& ul {
			@media #{$xs} {
				text-align: center;
			}

			@media #{$sm} {
				text-align: center;
			}

			& li {
				display: inline-block;

				& a {
					border-radius: 0;
					background   : $theme-color;
					color        : $white;
					border-color : $theme-color;

					@media #{$lg} {
						padding: 0 35px;
					}

					&:hover {
						border-color: #b7c3d6;
						background  : #b7c3d6;
					}

					&.main-btn {
						@media #{$xs} {
							margin-left : 0;
							margin-top  : 0px;
							margin-right: 10px;
							padding     : 0 30px;
							font-size   : 15px;
						}
					}

					&.main-btn-2 {
						background  : transparent;
						border-color: #0072FF;
						margin-right: 18px;
						color       : #0072FF;

						@media #{$xs} {
							margin-left : 0;
							margin-top  : 0px;
							margin-right: 10px;
							padding     : 0 30px;
							font-size   : 15px;
						}

						@media #{$sm} {
							margin-left: 18px;
							margin-top : 0;
						}

						&:hover {
							border-color: #0072FF;
							background  : #0072FF;
							color       : #fff;
						}
					}
				}
			}
		}
	}
}