/*-----------------------------------------------------------------------------------

    Template Name: Sample - Omnivus | Technology IT Solutions & Services HTML5 Template
    Template URI: http://ryetheme.com/demos/html/onmivus/
    Description: Sample - Technology IT Solutions & Services
    Author: MD THAHERIL ISLAM
    Author URI: https://ryetheme.com
    Version: 1.0

-----------------------------------------------------------------------------------

    CSS INDEX
    ===================

    01. Theme default CSS
	02. Header
    03. banner
	05. services
	06. portfolio
	07. faq
	08. team
	09. action
	10. blog
	11. footer

-----------------------------------------------------------------------------------*/
.sticky {
	background-color: white !important;

	.navbar {
		background-color: white !important;
	}
}

.header-area-2 {
	& .header-top {
		background-color: rgba(255, 255, 255, 0.7) !important;
	}

	& .header-nav {
		& .navigation {
			background-color: rgba(255, 255, 255, 0.7) !important;
		}
	}

}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
	background-color: rgba(0, 0, 0, 0.9) !important;
	z-index         : 9999;
}

.ReactModal__Content.ReactModal__Content--after-open {
	max-width: 620px;
	margin   : 0 auto;
}

form#enquire-form input {
	width       : 100%;
	border      : 1px solid #333;
	line-height : 50px;
	padding-left: 15px;
	color       : #2385C5;
}

form#enquire-form textarea {
	width  : 100%;
	padding: 15px;
}

button.close_btn {
	top             : 0;
	right           : 0;
	position        : absolute;
	background-color: transparent;
	padding         : 5px 11px;
	outline-offset  : unset;
	border          : 1px solid #333;
	font-weight     : 900;
	font-family     : "Lato", sans-serif;
}

.footer-overlay {
	border-top: 2px solid #0072ff;
}

.dropdown_main {
	position        : absolute;
	left            : 0;
	right           : 0;
	width           : 900px;
	background-color: #fff;
	padding         : 15px;
	display         : none;
	z-index         : 9999;
}

.inner_drop_box p {
	margin-bottom: 30px;
	color        : #333;
}

.inner_drop_box h4 {
	margin: 15px 0 10px 0;
	color : #333;
}

.inner_drop_box {
	text-align: center;
}

li.nav-item.sub-drop:hover .dropdown_main {
	display: block;
}

li.nav-item.sub-drop>a:after {
	content     : "";
	width       : 0;
	height      : 0;
	border-left : 8px solid transparent;
	border-right: 8px solid transparent;
	border-top  : 8px solid #333;
	position    : absolute;
	top         : 48%;
	right       : 0px;

	@media #{$lg} {
		border-left : 5px solid transparent;
		border-right: 5px solid transparent;
		border-top  : 5px solid #333;
	}

	@media #{$laptop} {
		border-left : 5px solid transparent;
		border-right: 5px solid transparent;
		border-top  : 5px solid #333;
	}
}

@media only screen and (max-device-width : 991px) {
	.sub-drop .dropdown_main {
		width: 100% !important;
	}

	.header-nav .navigation .navbar .navbar-nav .nav-item a {
		line-height: 50px !important;
	}

	.navbar-btn.mr-100 {
		display: none;
	}

	.navbar-light .navbar-brand img {
		width: 100px !important;
	}
}

@import 'variables';
@import 'mixin';
@import 'common';
@import 'header';
@import 'banner';
@import 'services';
@import 'video';
@import 'portfolio';
@import 'faq';
@import 'team';
@import 'action';
@import 'blog';
@import 'footer';
@import 'about';
@import 'what-we-do';
@import 'intro-video';
@import 'leadership';
@import 'progress-bar';
@import 'quote';
@import 'answers';
@import 'action-2';
@import 'news';
@import 'features';
@import 'choose';
@import 'portfolio-3';
@import 'counter';
@import 'get-in-touch';
@import 'about-experience';
@import 'about-intro';
@import 'about-history';
@import 'services-item';
@import 'services-plans';
@import 'services-optimization';
@import 'case-details';
@import 'team-join';
@import 'team-details';
@import 'shop';
@import 'shop-details';
@import 'blog-grid';
@import 'blog-standard';
@import 'blog-details';
@import 'error';
@import 'contact';
@import 'who-we-are';
@import 'solution';
@import 'latest-services';
@import 'why-choose';
@import 'case-studies';
@import 'team-member';
@import 'contact-us';
@import 'our-choose';
@import 'meet-us';
@import 'latest-news';

@import "custom";

.content-area {
	text-align: justify;
}

.content-area p {
	color: #333333;
}

.product-page-title-area {

	// height: 630px !important;
	margin-top: 15%;

	.product_banner_row.row {
		align-items: center;
	}

	.page-title-item {
		position: relative;

		h2.title {
			font-size: 80px;

			@media #{$xs} {
				font-size : 38px;
				text-align: center;
				margin-top: 5rem;
			}
		}

		p {
			font-size: 24px;
		}

		// .feat-product-image {
		// 	right: 0;
		// 	top: 17rem;
		// 	position: absolute;
		// }

	}

}

.get-in-touch-area.get-in-touch-area-2.product-inner-video.row {
	background    : unset !important;
	padding-top   : 0px;
	padding-bottom: 0px;

	h2 {
		font-size: 32px;
	}

	.get-map.d-none.d-lg-block {
		text-align: center;
		offset    : 0px, 4px rgba(51, 51, 51, 0.1);
		box-shadow: 0px 4px 16px 0px #3333331A;
	}
}

.product-inner-video.get-in-touch-area .get-map::before {
	border-bottom: unset !important;
	border-left  : unset !important;
}

.product-inner-video.get-in-touch-area.get-in-touch-area-2 .get-map a {
	height     : 80px;
	width      : 80px;
	line-height: 85px;
}

.product-inner-video img {
	height: 350px;
}

.product-inner-btn {
	padding  : 10px 30px;
	font-size: 20px;
}

.product-inner-slider {

	// .slider-text-content {
	// 	height: 250px;
	// }

	.slick-arrow {
		position : absolute;
		bottom   : 30%;
		width    : 1%;
		z-index  : 1;
		font-size: 50px;
	}

	.next {
		right: 100px;
	}

	.prev {
		left: 100px;
	}

	img {
		// height: 500px;
		margin: auto;
	}

	/*
	.slick-dots li button:before {
		color: #FFFFFF;
		border: 1px solid blue;
		border-radius: 25%;
		width: 50px;
	}
	*/

	.section-title-2 {

		.title {
			font-size: 30px;
		}

		p {
			color: #333333;
		}
	}
}


.product-inner-testimonial {

	span {
		font-size: 14px;
	}

	h2 {
		font-size: 42px;
	}

	.product-inner-subheading {
		font-size: 24px;
		color    : #333333;
	}
}

.get-in-touch-area.get-in-touch-area-2.product-inner-img.row {
	background    : unset !important;
	padding-top   : 0px !important;
	padding-bottom: 0px !important;
}

.product-inner-img.get-in-touch-area.get-in-touch-area-2 .get-map::before {
	right: -20px !important;
}

.product-inner-smart {
	background-color: #333333;

	text-align: center;

	@media #{$xs} {
		video {
			width: 100%;
		}
	}

	.product-inner-smart-content {

		padding-left : 0px !important;
		padding-right: 0px !important;

		h3 {
			font-size: 30px
		}

		p {
			font-size    : 24px;
			padding-left : 150px;
			padding-right: 150px;
		}

		h3,
		p {
			color: #FFFFFF;
		}

		.smart-face-image {
			width  : 35%;
			z-index: 1;
			bottom : 190px;
		}
	}
}


.product-inner-details {
	position   : relative;
	z-index    : 5;
	padding-top: 50px;

	& .section-title {
		& .title {
			font-size    : 42px;
			color        : #333333;
			margin-bottom: 38px;

			@media #{$lg} {
				font-size  : 42px;
				line-height: 52px;
			}

			@media #{$md} {
				font-size    : 46px;
				line-height  : 56px;
				margin-bottom: 0;
			}

			@media #{$xs} {
				font-size    : 26px;
				line-height  : 36px;
				margin-bottom: 0;
			}

			@media #{$sm} {
				font-size    : 40px;
				line-height  : 50px;
				margin-bottom: 0;
			}

			&::before {
				display: none;
			}
		}

		& p {
			font-size    : 15px;
			padding-right: 15px;
			line-height  : 35px;
			color        : #616161;
			padding-top  : 25px;
		}
	}

	& .product-inner-item {
		box-shadow         : 0px 10px 29.7px 0.3px rgba(225, 225, 225, 0.64);
		padding            : 60px 20px 60px 20px;
		position           : relative;
		overflow           : hidden;
		background-size    : contain;
		background-position: right;
		background-repeat  : no-repeat;
		@include transition(0.3s);
		background-color: $white;
		z-index         : 5;
		height          : 500px;
		display         : flex;
		flex-direction  : column;

		.product-inner-details-btn {
			margin-top: auto;
		}

		& span {
			color         : #333333;
			font-size     : 16px;
			text-transform: capitalize;
			padding-bottom: 15px;
		}

		&>svg {
			font-size     : 6rem;
			color         : $theme-color;
			padding-bottom: 35px;
			@include transition(0.3s);
		}

		& ul {
			list-style  : disc !important;
			padding-left: 20px;

			& li {
				color         : #333333;
				padding-bottom: 10px;
			}
		}

		& .title {
			font-size    : 20px;
			color        : #333333;
			margin-bottom: 20px;
			@include transition(0.3s);

			@media #{$lg} {
				font-size: 18px;
			}
		}

		& p {
			color      : #333333;
			font-size  : 15px;
			padding-top: 10px;
			@include transition(0.3s);
		}

		& a {
			// height       : 40px;
			// width        : 40px;
			text-align      : center;
			// line-height  : 40px;
			background      : #FFFFFF;
			color           : #0072FF;
			font-size       : 20px;
			bottom          : 8px;
			// position     : absolute;
			padding         : 10px 25px;
			border          : 2px solid rgba(0, 114, 255, 0.3);
			// left         : 15px;
			// border-radius: 50%;
			@include transition(0.3s);
		}

		&:hover {
			background-image: url(../images/ProductInnerPage/ProductInner-ProductDetails-BellaBot.png);
			background-size : cover;

			& svg {
				color: $white;
			}

			& .title {
				color: $white;
			}

			& span {
				color: $white;
			}

			& ul {
				li {
					color: $white;
				}
			}

			& p {
				color: $white;
			}

			& a {
				background-color: transparent;
				border          : 2px solid #FFFFFF;
				color           : #FFFFFF;

				& svg {
					color: $theme-color;
				}
			}
		}

		&::before {
			position              : absolute;
			content               : "";
			top                   : 0;
			left                  : 0;
			height                : 100%;
			width                 : 100%;
			// background-image   : url(../images/what-we-are-dot.png);
			background-size       : cover;
			background-position   : center;
			background-repeat     : no-repeat;
			z-index               : -1;

		}
	}
}

/*
@media (min-width: 992px) {
	.product-inner-details .col-lg-2 {
		max-width: 20% !important;
		flex     : 20% !important;
	}

}
*/

.product-inner-schemes {
	h3 {
		color    : #0072FF;
		font-size: 30px;
	}

	h4 {
		color    : #000000;
		font-size: 24px;
	}

	.product-inner-schemes-items {

		// img {
		// 	height: 300px;
		// }

		p {
			color  : #FFFFFF;
			padding: 0px 100px;
		}
	}
}

@media (max-width: 1024px) {
	.product-page-title-area {

		.page-title-item {

			// .feat-product-image {
			// 	top: 24rem;
			// }

		}
	}

	.get-in-touch-area.get-in-touch-area-2.product-inner-video.row h2 {
		font-size: 40px;
	}

	.inner-product-padding-view {

		.product-inner-video {
			img {
				height: 250px;
			}
		}

		.get-map.d-none.d-lg-block {
			display      : block !important;
			margin-bottom: 50px;
		}
	}

	.product-inner-slider {

		.slick-track {

			.slick-slide {

				.slider-text-content {

					height: 250px;

				}
			}
		}
	}

	.product-inner-smart {
		height: 525px;

		.product-inner-smart-content {

			p {
				padding-right: 50px;
				padding-left : 50px;
			}

			.smart-face-image {
				width : 40%;
				bottom: 150px;
			}
		}
	}

	.product-inner-testimonial {
		h2 {
			font-size: 40px;
		}
	}

	.product-inner-details {

		.section-title {

			.title {
				font-size: 40px;
			}
		}
	}

	.product-inner-schemes {

		.product-inner-schemes-items {

			img {
				height: 200px;
			}

			p {
				font-size: 14px;
				padding  : 0px 30px;
			}
		}
	}
}

@media (max-width: 992px) {
	.product-inner-details {

		.product-inner-item {
			height: 400px;
		}
	}
}

@media (max-width: 767px) {

	.product-page-title-area {
		// height: 300px !important;

		.page-title-item {

			p {
				text-align: center;
			}

			// .feat-product-image {
			// 	top: unset;
			// }

		}
	}

	.inner-product-padding-view {
		padding-top: 5rem;
	}

	.get-in-touch-area.get-in-touch-area-2.product-inner-video.row h2 {
		font-size: 30px;
	}

	.product-inner-slider {

		.slick-track {

			.slick-slide {

				img {
					height: 300px;
				}

				.slider-text-content {

					height       : 350px;
					padding-left : 0px;
					padding-right: 0px;

					h3 {
						font-size: 20px;
					}

					p {
						font-size: 14px;
					}

				}
			}
		}
		video {
			width: 100%;
		}
	}

	.product-inner-testimonial {
		h2 {
			font-size: 30px;
		}

		.product-inner-subheading {
			font-size: 20px;
		}
	}

	.product-inner-smart {
		height: 550px;

		.product-inner-smart-content {

			h3 {
				font-size: 24px;
			}

			p {
				padding-left : 0px;
				padding-right: 0px;
				font-size    : 20px;
			}

			.smart-face-image {
				width : 100%;
				bottom: 150px;
			}
		}
	}

	.product-inner-details {

		.section-title {

			.title {
				font-size: 30px;
			}
		}

		.product-inner-item {
			height: 400px;
		}
	}

	.product-inner-schemes {

		h3 {
			font-size: 24px;
		}

		h4 {
			font-size: 20px;
		}

		.product-inner-schemes-items {

			img {
				height: 150px;
			}

			p {
				font-size: 14px;
				padding  : 0px;
			}
		}
	}
}

.scenarios-thumb.intro-thumb:before {
	display: none;
}

.scenarios-thumb.intro-thumb a {
	height     : 50px !important;
	width      : 50px !important;
	line-height: 80px !important;
}

.scenarios-thumb.intro-thumb button {
	height     : 50px !important;
	width      : 50px !important;
	line-height: 75px !important;
}

.scenarios-thumb.intro-thumb .svg-inline--fa.fa-w-14 {
	width  : 20px !important;
	padding: 0 !important;
}

.scenerios-section {
	padding-top   : 100px;
	padding-bottom: 100px;
}