/*===============================
    40.LATEST NEWS css 
================================*/

.latest-news-area {
    margin-top    : 100px;
    padding-bottom: 50px;
    padding-top   : 30px;

    @media #{$xs} {
        margin-top: 0;
    }

    & .section-title {
        & span {
            letter-spacing: 3px;
        }

        & .title {
            font-size    : 43px;
            line-height  : 65px;
            color        : #0072ff;
            margin-bottom: 80px;

            @media #{$lg} {
                font-size: 38px;
            }

            @media #{$md} {
                font-size  : 40px;
                line-height: 50px;
                padding    : 0 135px;
            }

            @media #{$xs} {
                font-size  : 20px;
                line-height: 32px;
            }

            @media #{$sm} {
                font-size  : 20px;
                line-height: 32px;
                padding    : 0 80px;
            }

            &::before {
                display: none;
            }
        }
    }

    & .letast-news-grid {
        padding-bottom: 80px;
        padding-top   : 42px;
        box-shadow    : 0px 16px 32px 0px rgba(228, 228, 228, 0.56);

        @media #{$xs} {
            margin-left : 20px;
            margin-right: 20px;
        }

        & .letest-news-item {
            position: relative;
            height  : 100%;

            @media #{$sm} {
                padding: 0 65px;
            }

            & span {
                font-size     : 14px;
                color         : $theme-color;
                font-weight   : 700;
                padding-bottom: 7px;

            }

            & .title {
                font-size     : 20px;
                line-height   : 35px;
                padding-bottom: 25px;

                @media #{$lg} {
                    font-size  : 18px;
                    line-height: 30px;
                }

                @media #{$md} {
                    font-size  : 22px;
                    line-height: 32px;
                }

                @media #{$xs} {
                    font-size  : 17px;
                    line-height: 27px;
                }

                @media #{$sm} {
                    font-size  : 24px;
                    line-height: 35px;
                }

                & a {
                    color: $black;
                }
            }

            & p {
                color      : #616161;
                font-size  : 15px;
                line-height: 32px;
            }

            &>a {
                font-size       : 15px;
                color           : #0072ff;
                background-color: transparent;
                border-color    : $theme-color;
                padding         : 0 40px;
                margin-top      : 32px;
                font-family     : 'Lato', sans-serif;
                position        : absolute;
                bottom          : 0;
                @media #{$xs} {
                    position        : unset !important;
                }

                &:hover {
                    background: $theme-color;
                    color     : $white;
                }
            }
        }

    }
}




.brand-2-area {
    padding-top: 120px;

    & .brand-item {
        text-align: center;

        & img {
            opacity: .2;
            @include transition(0.3s);
            display: inline-block;

            &:hover {
                opacity: 1;
            }
        }
    }
}