.about-our-Value-area {
    & .experience-item {
        display: block;
        width  : 100%;

        & h2 {
            margin-bottom: 50px;

            @media (max-width: 992px) {
                text-align: center;
                display   : block;
                width     : 100%;
            }
        }
    }

    & .our-value-icons {
        text-align      : center;
        width           : 100px;
        height          : 100px;
        margin          : 0 auto;
        background-color: #0072ff;
        border-radius   : 50%;
        position        : relative;

        & svg {
            position: absolute;
            left    : 0;
            right   : 0;
            margin  : 0 auto;
            top     : 30%;
        }
    }

    & .our-value-label {
        text-align: center;
        margin-top: 30px;

        & p {
            color: #333333;
        }
    }
}

@media (min-width: 992px) {
    .about-our-Value-area .col-lg-2 {
        max-width: 20% !important;
        flex     : 20% !important;
    }

}