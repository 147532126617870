/*===========================
    15.QUOTE css 
===========================*/

.quote-area {
	background-attachment: fixed;

	& .quote-overlay {
		// background-color: rgba(0, 18, 34,.8);
		padding-top   : 105px;
		padding-bottom: 120px;

		@media #{$xs} {
			padding-top   : 30px;
			padding-bottom: 30px;
		}

		& .section-title-2 {
			& .title {
				color    : #333333;
				font-size: 43px;
				@media #{$xs} {
					font-size: 20px;
				}
			}

			& p {
				color: #333333;
			}
		}

		& .quote-form {
			& .input-box {
				position: relative;

				& input {
					width           : 100%;
					background-color: rgba(255, 255, 255, 0.5);
					border          : 2px solid rgba(255, 255, 255, .2);
					padding-left    : 40px;
					line-height     : 45px;
					color           : #333333;

					&::placeholder {
						opacity: 1;
						color  : #333333;
					}
				}

				& textarea {
					width           : 100%;
					height          : 200px;
					background-color: rgba(255, 255, 255, 0.5);
					border          : 2px solid rgba(255, 255, 255, .2);
					padding-top     : 30px;
					padding-left    : 40px;
					color           : #333333;
					resize          : none;

					&::placeholder {
						opacity: 1;
						color  : #333333;
					}
				}

				& button {
					margin-top   : 38px;
					border-radius: 0;
				}

				& svg {
					position: absolute;
					top     : 35px;
					right   : 40px;

				}
			}
		}
	}
}