/*===============================
    34.CONTACT css 
================================*/


.contact-details-area {
	& .contact-info {
		border: 1px solid $border-color-2;

		@media #{$xs} {
			margin-right: 0;
		}

		& .contact-item-1 {
			display: flex;

			@media #{$xs} {
				display: block;
			}

			@media #{$sm} {
				display: flex;
			}

			& .contact-info-item {
				border : 1px solid $border-color-2;
				width  : 100%;
				padding: 55px 0 50px;

				& i {
					height       : 80px;
					width        : 80px;
					border-radius: 50%;
					background   : $theme-color;
					text-align   : center;
					line-height  : 80px;
					color        : $white;
					font-size    : 24px;

				}

				& .title {
					font-size  : 24px;
					padding-top: 25px;
				}

				& p {
					font-size  : 18px;
					color      : #0072ff;
					padding-top: 5px;
				}

				& ul {
					& li {
						display    : inline-block;
						padding    : 5px;
						margin-left: 5px;
					}
				}

			}
		}
	}

	& .map-area {
		& iframe {
			width : 100%;
			height: 550px;

		}
	}
}

.get-in-touch-area-2 {
	.form-area {
		.input-box {
			input {
				box-shadow: 0px 5px 8px rgba(0, 114, 255, 0.08);
			}
			textarea {
				box-shadow: 0px 5px 8px rgba(0, 114, 255, 0.08);
			}
		}
	}
}