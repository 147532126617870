.business-cooperation-form {
    & #contact-form {
        & label {
            color      : #333;
            font-size  : 20px;
            line-height: 32px;
        }
    }

    span.bcf-type-btn {
        height     : 50px;
        background : #fff;
        display    : block;
        width      : 100%;
        color      : #333;
        padding-top: 12px;
        font-family: Lato;
        font-style : normal;
        font-weight: bold;
        font-size  : 20px;
        line-height: 24px;
        text-align : center;
        background : #FFFFFF;
        box-shadow : 0px 5px 8px rgba(0, 114, 255, 0.08);
        cursor     : pointer;

        &::hover {
            background: #0072FF !important;
            color     : #fff !important;
        }

        @media (max-width: 767px) {
            margin-bottom: 20px;
        }
    }



    .input-box {
        >.row {
            align-items: center;
        }
    }
}

.business-cooperation-form .active {
    background: #0072FF !important;
    color     : #fff !important;
}