/*===========================
    21.GET IN TOUCH css 
===========================*/


.get-in-touch-area {
	padding-top   : 113px;
	padding-bottom: 120px;
	background    : #f7fcff;

	p {
		color: #333;
	}

	& .section-title {
		& .title {
			font-size  : 43px;
			line-height: 70px;
			color      : #0072ff;

			@media #{$xs} {
				font-size  : 34px;
				line-height: 44px;
			}

			@media #{$sm} {
				font-size  : 52px;
				line-height: 62px;
			}

			&::before {
				display: none;
			}
		}
	}

	& .form-area {
		& .input-box {

			& input {
				width       : 100%;
				border      : 1px solid $white;
				line-height : 70px;
				padding-left: 30px;
				color       : #2385C5;

				&::placeholder {
					opacity: 1;
					color  : #2385C5;
				}
			}

			& textarea {
				width       : 100%;
				border      : 1px solid $white;
				height      : 170px;
				padding-top : 30px;
				padding-left: 30px;
				color       : #2385C5;
				resize      : none;

				&::placeholder {
					opacity: 1;
					color  : #2385C5;
				}
			}

			& button {
				border-radius: 0;
				margin-top   : 20px;
				background   : $theme-color;
				border-color : $theme-color;
				color        : $white;
				font-family  : "Lato", sans-serif;
			}
		}
	}

	& .get-map {
		position: relative;

		& iframe {
			width   : 530px;
			height  : 560px;
			position: absolute;
			right   : 0;
			top     : 55px;

			@media #{$lg} {
				width: 100%;
			}

		}

		&::before {
			position     : absolute;
			content      : '';
			height       : 0;
			width        : 0;
			border-bottom: 400px solid $theme-color;
			border-left  : 400px solid transparent;
			right        : -20px;
			bottom       : -635px;
		}
	}

	& .testimonials-get {
		position: relative;

		&::before {
			border-bottom: 280px solid $theme-color;
		}

		p {
			color: #333;
		}
	}

	&.get-in-touch-area-2 {
		& .get-map {
			position: relative;
			z-index : 3;

			&::before {
				right  : 20px;
				bottom : -20px;
				z-index: -1;
			}

			& a {
				position     : absolute;
				left         : 50%;
				top          : 50%;
				transform    : translate(-50%, -50%);
				height       : 120px;
				width        : 120px;
				text-align   : center;
				line-height  : 120px;
				color        : $white;
				font-size    : 24px;
				z-index      : 5;
				border-radius: 50%;

				&::before {
					position     : absolute;
					content      : '';
					height       : 100%;
					width        : 100%;
					border-radius: 50%;
					background   : #fff;
					top          : 0;
					left         : 0;
					z-index      : -2;
					opacity      : .6;
					animation    : play-btn-1 1.5s linear infinite;
				}

				&::after {
					position     : absolute;
					content      : '';
					height       : 100%;
					width        : 100%;
					top          : 0;
					left         : 0;
					background   : $theme-color;
					border-radius: 50%;
					z-index      : -1;
				}

			}
		}

		& .testimonials-get {
			position: relative;

			&::before {
				border-bottom: 280px solid $theme-color;
			}

			p {
				color: #333;
			}
		}

		& .form-area {
			& .input-box {
				position: relative;

				& i {
					position: absolute;
					right   : 30px;
					top     : 25px;
				}
			}
		}
	}
}