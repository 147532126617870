/*===============================
    35.WHO WE ARE css 
================================*/

.who-we-are-area {
	position   : relative;
	z-index    : 5;
	padding-top: 50px;

	& .slick-arrow {
		position   : absolute;
		transform  : translateX(-50%);
		bottom     : -6rem;
		height     : 80px;
		width      : 80px;
		background : $white;
		text-align : center;
		line-height: 80px;
		box-shadow : 0px 8px 16px 0px rgba(132, 190, 255, 0.1);
		cursor     : pointer;

		& i {
			font-size: 20px;
			@include transition(0.3s);
		}

		&.prev {
			left: 45%;
		}

		&.next {
			right: 39%;

			&::before {
				position: absolute;
				content : '';
				width   : 2px;
				height  : 50px;
				left    : -1px;
				bottom  : -12%;
				background:
					#e9f1ff;
				transform: translate(-50%, -50%);
			}

			@media #{$lg} {
				right: 36%;
			}
		}

		&:hover {
			& i {
				color: $theme-color;
			}
		}

	}

	& .section-title {
		& .title {
			font-size    : 42px;
			color        : #0072ff;
			margin-bottom: 38px;

			@media #{$lg} {
				font-size  : 42px;
				line-height: 52px;
			}

			@media #{$md} {
				font-size    : 46px;
				line-height  : 56px;
				margin-bottom: 0;
			}

			@media #{$xs} {
				font-size    : 20px;
				line-height  : 32px;
				margin-bottom: 0;
			}

			@media #{$sm} {
				font-size    : 40px;
				line-height  : 50px;
				margin-bottom: 0;
			}

			&::before {
				display: none;
			}
		}

		& p {
			font-size       : 16px;
			// padding-right: 15px;
			line-height     : 35px;
			text-align      : justify;
			color           : #333;
			// padding-top  : 25px;

		}
	}

	& .what-we-do-item {
		box-shadow         : 0px 10px 29.7px 0.3px rgba(225, 225, 225, 0.64);
		padding            : 20px 20px 60px 10px;
		position           : relative;
		overflow           : hidden;
		background-size    : auto;
		background-position: right;
		background-repeat  : no-repeat;
		@include transition(0.3s);
		background-color: $white;
		z-index         : 5;
		min-height      : 300px;
		margin          : 0 15px;

		& span {
			color         : #2385C5;
			font-size     : 16px;
			text-transform: capitalize;
			padding-bottom: 15px;
		}

		&>svg {
			font-size     : 6rem;
			color         : $theme-color;
			padding-bottom: 35px;
			@include transition(0.3s);
		}

		& ul {
			list-style  : disc !important;
			padding-left: 20px;

			& li {
				color         : #333333;
				padding-bottom: 10px;
			}
		}

		& .title {
			font-size    : 20px;
			color        : #0072ff;
			margin-bottom: 20px;
			@include transition(0.3s);

			@media #{$lg} {
				font-size: 18px;
			}
		}

		& p {
			color      : #616161;
			font-size  : 15px;
			padding-top: 10px;
			@include transition(0.3s);
		}

		& a {
			height       : 40px;
			width        : 40px;
			text-align   : center;
			line-height  : 40px;
			background   : $theme-color;
			color        : $white;
			font-size    : 14px;
			bottom       : 8px;
			position     : absolute;
			left         : 15px;
			border-radius: 50%;
			@include transition(0.3s);
		}

		&:hover {
			background      : $theme-color;
			background-image: none !important;

			&::before {
				position           : absolute;
				content            : "";
				top                : 0;
				left               : 0;
				height             : 100%;
				width              : 100%;
				background-image   : url(../images/what-we-are-dot.png);
				background-size    : cover;
				background-position: center;
				background-repeat  : no-repeat;
				z-index            : -1;

			}

			& svg {
				color: $white;
			}

			& .title {
				color: $white;
			}

			& span {
				color: $white;
			}

			& ul {
				li {
					color: $white;
				}
			}

			& p {
				color: $white;
			}

			& a {
				background: $white;

				& svg {
					color: $theme-color;
				}
			}
		}

	}

	& .what-we-are-shape-1 {
		position: absolute;
		top     : 0;
		left    : 0;
		z-index : -1;

		@media #{$xs} {
			width: 40%;
		}
	}

	& .what-we-are-shape-2 {
		position: absolute;
		top     : 0;
		right   : 0;
		z-index : -1;
		width   : 300px;

		@media #{$xs} {
			width: 40%;
		}
	}
}

@media (min-width: 992px) {
	.who-we-are-area .col-lg-2 {
		max-width: 20% !important;
		flex     : 20% !important;
	}

}