/*===========================
    14.LEADERSHIP css 
===========================*/

.leadership-area {
	& .leadership-item {
		position            : relative;
		box-shadow          : 0px 4px 16px rgba(51, 51, 51, 0.1);
		// padding-bottom   : 60px;
		// min-width     : 350px !important;
		// min-height    : 343px !important;

		& img {
			width    : 100%;
			// border: 10px solid $white;

		}

		& .leadership-content {
			position      : absolute;
			right         : 0;
			bottom        : 0px;
			width         : 90%;
			background    : $white;
			padding-top   : 20px;
			padding-bottom: 23px;
			padding-left  : 40px;
			box-shadow    : 0px 8px 16px 0px rgba(132, 190, 255, 0.1);

			@media #{$sm} {
				padding-left: 20px;
			}

			& .title {
				font-size: 24px;

				@media #{$sm} {
					font-size: 20px;
				}
			}

			& span {
				color      : $theme-color;
				font-weight: 700;
				padding-top: 9px;
			}

		}

	}

	& .leadership-active {
		& .slick-arrow {
			position   : absolute;
			transform  : translateX(-50%);
			bottom     : -35px;
			height     : 80px;
			width      : 80px;
			background : $white;
			text-align : center;
			line-height: 80px;
			box-shadow : 0px 8px 16px 0px rgba(132, 190, 255, 0.1);
			cursor     : pointer;

			& i {
				font-size: 20px;
				@include transition(0.3s);
			}

			&.prev {
				left: 45%;
			}

			&.next {
				right: 39%;

				&::before {
					position: absolute;
					content : '';
					width   : 2px;
					height  : 50px;
					left    : -1px;
					bottom  : -12%;
					background:
						#e9f1ff;
					transform: translate(-50%, -50%);
				}

				@media #{$lg} {
					right: 36%;
				}
			}

			&:hover {
				& i {
					color: $theme-color;
				}
			}

		}
	}

	&.padding {
		padding-bottom: 252px;
	}

	& .section-title-2 {
		& p {
			color: #0072ff;
		}

		& .title {
			margin-top    : 0;
			padding-bottom: 15px;
		}
	}

	& .slick-slide {
		margin: 0 15px;
	}
}

.about_leader .leadership-area .leadership-active .slick-arrow {
	bottom: 100px;
}

@media #{$xs} {
	.leadership-active .slick-slide.slick-cloned {
		display: none !important;
	}

	.leadership-area .slick-slide {
		margin : 0 !important;
		padding: 0 15px;
	}
}