/*===========================
    2.HEADER css 
===========================*/

.transparent-color {
	background-color: transparent !important;
}

.bg-white {
	background-color: white !important;
}




.header-top {
	position  : relative;
	background: $theme-color;

	@media #{$xs} {
		padding-left : 0;
		padding-right: 0;
	}

	@media #{$sm} {
		padding-left : 30px;
		padding-right: 30px;
	}

	& .header-left-side {
		position: relative;

		& ul {
			& li {
				display: inline-block;

				& a {
					color       : $white;
					line-height : 40px;
					margin-right: 40px;
					font-size   : 14px;

					@media #{$lg} {
						margin-right: 15px;
					}

					@media #{$xs} {
						margin-right: 25px;
					}
				}
			}
		}

		&::before {
			position  : absolute;
			content   : '';
			height    : 100%;
			width     : 400px;
			background: $white;
			top       : 0;
			right     : -70px;
			transform : skew(-45deg);

			@media #{$lg} {
				right: -220px;
			}

			@media #{$md} {
				transform: skew(45deg);
			}

			@media #{$xs} {
				display: none;
			}

			@media #{$sm} {
				transform: skew(45deg);
				right    : -200px;
				display  : block;
			}
		}
	}

	& .header-right-social {
		position: relative;

		& ul {
			& li {
				display: inline-block;

				& a {
					color      : $white;
					margin-left: 24px;
					line-height: 40px;
				}
			}
		}

		&::before {
			position  : absolute;
			content   : '';
			height    : 100%;
			width     : 400px;
			background: $white;
			top       : 0;
			left      : -70px;
			transform : skew(45deg);

			@media #{$lg} {
				left: -170px;
			}

			@media #{$xs} {
				display: none;
			}

			@media #{$sm} {
				left   : -200px;
				display: block;
			}
		}
	}

}



.header-nav {
	-webkit-box-shadow: 0 0 18px 1px rgba(0, 0, 0, .1);
	box-shadow        : 0 0 18px 1px rgba(0, 0, 0, .1);

	#navbar.is-sticky {
		position        : fixed !important;
		top             : 0 !important;
		z-index         : 9999;
		background-color: #fff !important;
	}

	& .navigation {
		position     : absolute;
		top          : 40px;
		right        : 0;
		left         : 0;
		background   : $white;
		padding-left : 30px;
		padding-right: 30px;
		z-index      : 99;

		@media #{$md} {
			top: 80px;
		}

		@media #{$xs} {
			top          : 80px;
			padding-left : 0;
			padding-right: 0;
		}

		@media #{$sm} {
			padding-left : 30px;
			padding-right: 30px;
		}

		&.sticky {
			position  : fixed;
			top       : 0;
			left      : 0;
			right     : 0;
			background: $white;
			box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.04);
			z-index   : 999;
			animation : sticky .4s;

			& .navbar {
				& .navbar-nav {
					& .nav-item {
						& a {
							line-height: 80px;

							@media #{$lg} {
								font-size: 15px;
								padding  : 0 10px;
							}
						}
					}
				}
			}
		}

		& .navbar {
			position: relative;
			padding : 0;

			& .country-flag {
				& img {
					border       : 5px solid $white;
					border-radius: 6px;
					box-shadow   : 0px 8px 16px 0px rgba(60, 110, 203, 0.2);
				}
			}

			& .navbar-toggler {
				& .toggler-icon {
					width           : 30px;
					height          : 2px;
					background-color: $heading-color;
					margin          : 5px 0;
					display         : block;
					position        : relative;
					@include transition(0.3s);
				}

				&.active {
					& .toggler-icon {
						&:nth-of-type(1) {
							@include transform(rotate(45deg));
							top: 7px;
						}

						&:nth-of-type(2) {
							opacity: 0;
						}

						&:nth-of-type(3) {
							@include transform(rotate(135deg));
							top: -7px;
						}
					}
				}
			}

			& .navbar-collapse {
				@media #{$md} {
					position        : absolute;
					top             : 100%;
					left            : 0;
					width           : 100%;
					background-color: $white;
					z-index         : 8;
					padding         : 10px 16px;
					box-shadow      : 0 26px 48px 0 rgba(0, 0, 0, .15);
				}

				@media #{$xs} {
					position        : absolute;
					top             : 100%;
					left            : 0;
					width           : 100%;
					background-color: $white;
					z-index         : 8;
					padding         : 10px 16px;
					box-shadow      : 0 26px 48px 0 rgba(0, 0, 0, .15);
				}
			}

			& .navbar-nav {


				@media #{$md} {
					margin-right: 0;
				}

				@media #{$xs} {
					margin-right: 0;
				}

				& .nav-item {
					position: relative;

					@media #{$lg} {
						margin-right: 5px;
					}

					& a {
						font-size     : 16px;
						font-weight   : 700;
						padding       : 0 30px;
						color         : #000;
						text-transform: uppercase;
						position      : relative;
						@include transition(0.3s);
						margin     : 0;
						line-height: 120px;

						@media #{$lg} {
							font-size: 15px !important;
							padding  : 0 10px !important;
						}

						@media #{$laptop} {
							font-size: 16px !important;
							padding  : 0 15px !important;
						}

						@media #{$md} {
							padding    : 0;
							display    : block;
							border     : 0;
							margin     : 0;
							line-height: 40px;
						}

						@media #{$xs} {
							padding    : 0;
							display    : block;
							border     : 0;
							margin     : 0;
							line-height: 40px;
						}

						& span {
							padding-left: 5px;
							font-size   : 15px;

							@media #{$md} {
								display: none;
							}

							@media #{$xs} {
								display: none;
							}
						}
					}

					& .sub-menu {
						position        : absolute;
						left            : 0;
						top             : 110%;
						width           : 260px;
						background-color: $white;
						opacity         : 0;
						visibility      : hidden;
						@include transition(0.3s);
						z-index: 99;
						@include box-shadow (0 2px 6px 0 rgba(0, 0, 0, 0.16));


						@media #{$md} {
							position  : relative !important;
							width     : 100% !important;
							left      : 0 !important;
							top       : auto !important;
							opacity   : 1 !important;
							visibility: visible !important;
							display   : none;
							right     : auto;
							@include transform(translateX(0%));
							@include transition(none);
							@include box-shadow(none);
							text-align: left;
							border-top: 0;
						}

						@media #{$xs} {
							position  : relative !important;
							width     : 100% !important;
							left      : 0 !important;
							top       : auto !important;
							opacity   : 1 !important;
							visibility: visible !important;
							display   : none;
							right     : auto;
							@include transform(translateX(0%));
							@include transition(none);
							@include box-shadow(none);
							text-align: left;
							border-top: 0;
						}

						&>li {
							position: relative;

							& .sub-nav-toggler {
								color: $heading-color;
								@include transition(0.3s);
							}

							& a {
								display : block;
								padding : 8px 24px;
								position: relative;
								color   : $black-soft;
								@include transition(0.3s);
								border-radius: 0;
								margin       : 0 0;
								line-height  : 30px;

								& i {
									float     : right;
									font-size : 14px;
									margin-top: 5px;

									@media #{$md} {
										display: none;
									}

									@media #{$xs} {
										display: none;
									}
								}

								& .sub-nav-toggler {
									& i {
										display: inline-block;
									}
								}
							}

							& .sub-menu {
								right     : auto;
								left      : 100%;
								top       : 0;
								opacity   : 0;
								visibility: hidden;
								@include transition(0.3s);

								@media #{$md} {
									padding-left: 30px;
								}

								@media #{$xs} {
									padding-left: 30px;
								}
							}

							&:hover {
								& .sub-menu {
									top       : 100%;
									opacity   : 1;
									visibility: visible;
								}

								& .sub-nav-toggler {
									color: $white;
								}

								&>a {
									background-color: $theme-color;
									color           : #fff !important;
								}
							}
						}
					}

					&:hover {
						& .sub-menu {
							opacity   : 1;
							visibility: visible;
							top       : 100%;
						}
					}

					.sub-nav-toggler {
						display: none;

						@media #{$md} {
							display   : inline-block;
							position  : absolute;
							top       : 0;
							right     : 0;
							padding   : 10px 14px;
							font-size : 16px;
							background: none;
							border    : 0;
							color     : $heading-color;
						}

						@media #{$xs} {
							display   : inline-block;
							position  : absolute;
							top       : 0;
							right     : 0;
							padding   : 10px 14px;
							font-size : 16px;
							background: none;
							border    : 0;
							color     : $heading-color;
						}
					}
				}
			}

			& .navbar-btn {

				@media #{$laptop} {
					margin-right: 50px;
				}

				@media #{$lg} {
					margin-right: 30px;
				}

				@media #{$md} {
					position    : absolute;
					right       : 70px;
					top         : 3px;
					margin-right: 0;
				}

				@media #{$xs} {
					display: none;
				}

				@media #{$sm} {
					position    : absolute;
					right       : 70px;
					top         : 3px;
					margin-right: 0;
					display     : block;
				}

				& .main-btn {
					color: $white;

					@media #{$lg} {
						padding: 0 15px;
					}

					&:hover {
						border-color: $theme-color;
						color       : $theme-color;
					}
				}
			}

			& .navbar-btn {
				& a {
					color: $heading-color;
				}
			}
		}
	}
}





@-webkit-keyframes sticky {
	0% {
		top: -200px;
	}

	100% {
		top: 0;
	}
}

@keyframes sticky {
	0% {
		top: -200px;
	}

	100% {
		top: 0;
	}
}







.header-area {
	& .header-top {
		position           : absolute;
		top                : 0;
		left               : 0;
		right              : 0;
		// background-color: transparent !important;
		border-bottom      : 2px solid #0072FF;
		z-index            : 99;

		@media #{$xs} {
			display: none;
		}

		@media #{$sm} {
			display: block;
		}

		& .header-left-side {
			&::before {
				display: none;
			}

			& ul {
				& li {
					& a {
						color      : $text-color-2;
						line-height: 55px;

						@media #{$md} {
							margin-right: 30px;
						}

						@media #{$sm} {
							margin-right: 4px;
						}

						& i {
							padding-right: 6px;
						}
					}
				}
			}
		}

		& .header-right-social {
			&::before {
				display: none;
			}

			& ul {
				& li {

					& a {
						color      : $text-color-2;
						line-height: 55px;
						@include transition(0.3s);

						&:hover {
							color: $theme-color;
						}

						@media #{$sm} {
							margin-left: 20px;
						}
					}
				}
			}
		}
	}

	& .header-nav {
		& .navigation {
			top                : 57px;
			// background-color: transparent !important;

			@media #{$md} {
				top: 57px;
			}

			@media #{$xs} {
				top          : 0px;
				padding-left : 10px;
				padding-right: 10px;
			}

			@media #{$sm} {
				top          : 57px;
				padding-left : 30px;
				padding-right: 30px;
			}

			& .navbar {

				& .navbar-btn {
					margin-right: 0;

					& a {
						border-radius: 0;
					}
				}
			}

			&.sticky {
				background: $white;
				top       : 0;

				& .navbar {
					& .navbar-nav {
						& .nav-item {
							& a {
								line-height: 40px;
							}
						}
					}
				}
			}
		}
	}
}

.header-area-3 {
	& .header-nav {
		& .navigation {
			top             : 20px;
			background-color: transparent !important;

			& .navbar {
				& .navbar-toggler {
					border-color: $white;

					& span {
						background: $white;
					}
				}

				& .navbar-collapse {
					@media #{$md} {
						background: $heading-color;
					}

					@media #{$xs} {
						background: $heading-color;
					}
				}

				& .navbar-nav {
					& .nav-item {
						margin-right: 0;

						@media #{$lg} {
							margin-right: 0;
						}

						&>a {
							line-height  : 100px;
							padding-right: 55px !important;
							padding      : 0;
							color        : $white;

							@media #{$lg} {
								padding: 0px 10px !important;
							}

							@media #{$md} {
								line-height: 40px;
							}

							@media #{$xs} {
								line-height: 40px;
							}

							& .sub-nav-toggler {
								color: $white;
							}
						}
					}
				}

				& .navbar-btn {
					& a {
						background-color: transparent !important;
						color           : $white;
						border-color    : #000;
						border-color    : rgba(245, 250, 255, .1);
						border-radius   : 6px;

						&:hover {
							background  : $theme-color;
							border-color: $theme-color;
						}
					}
				}
			}

			&.sticky {
				top       : 0;
				background: $heading-color;
			}
		}
	}
}

.header-area-4 {
	& .header-top {
		background   : #fff !important;
		border-bottom: 2px solid #ebebeb;

		& .header-left-side {
			& ul {
				& li {
					& a {
						color: #616161;

						& i {
							color: $theme-color;
						}
					}
				}
			}
		}

		& .header-right-social {
			& ul {
				& li {
					& a {
						color: #aeaeae;

						&:hover {
							color: $theme-color;
						}
					}
				}
			}
		}
	}

	& .header-nav {
		& .navigation {
			background-color: #fff !important;

			@media #{$md} {
				top: 57px;
			}

			@media #{$xs} {
				top: 0px;
			}

			@media #{$sm} {
				top: 57px;
			}

			& .navbar {
				& .navbar-nav {
					& .nav-item {
						@media #{$lg} {
							margin-right: 0;
						}

						& a {
							margin     : 0 18px;
							padding    : 0 0;
							color      : #555;
							font-size  : 18px;
							line-height: 40px;

							@media #{$lg} {
								font-size: 16px;
								margin   : 0 16px;
							}

							@media #{$md} {
								padding: 5px 0;
							}

							@media #{$xs} {
								padding: 5px 0;
							}

							&::before {
								// width: 0%;
								// height: 2px;
								//background: $theme-color;
								//position: absolute;
								//content: '';
								// top: -2px;
								// right: 0;
								// left: auto;
								// @include transition(0.3s);
								// @media #{$md} {
								// 	display: none;
								// }
								// @media #{$xs} {
								// 	display: none;
								// }
							}

							&:hover {
								&::before {
									width: 100%;
									right: auto;
									left : 0;
								}
							}
						}

						& .sub-menu {
							&>li {
								& a {
									line-height: 50px;
									margin     : 0;
									padding    : 0 22px;

									&::before {
										display: none;
									}
								}
							}
						}

						&.active {
							& a {
								&::before {
									width: 100%;
								}
							}
						}
					}
				}

				& .bar-area {
					& ul {
						& li {
							display: inline-block;

							& a {
								margin-right: 30px;
								font-size   : 18px;
								color       : #555;
							}

							&:first-child {
								& a {
									padding-left: 20px;
								}
							}

							&:last-child {
								& a {
									color    : $theme-color;
									font-size: 24px;
								}
							}
						}
					}
				}

				& .navbar-btn {
					@media #{$md} {
						top: 5px;
					}

					@media #{$sm} {
						top: 5px;
					}

					& a {
						line-height  : 55px;
						padding      : 0 35px;
						border-radius: 5px;

						@media #{$lg} {
							font-size: 14px;
							padding  : 0 10px;
						}
					}
				}
			}

			&.sticky {
				& .navbar {
					& .navbar-nav {
						& .nav-item {
							& a {
								&::before {
									width: 0;
								}
							}
						}
					}
				}
			}
		}
	}
}



.dropdown-toggle::after {
	display: none;
}



@mixin header-layout-fixed {
	.header-nav {
		.navigation {
			background-color: transparent !important;

			.navbar {
				background-color: transparent !important;

				.navbar-nav .nav-item a {

					line-height: 48px;
				}
			}
		}
	}
}


.layout1-header {




	@extend .header-area;
	@extend .header-area-2;
	@extend .header-area-4;

	.country-flag {
		display: none !important;
	}

	.container-fluid {
		display: none !important;
	}

	@include header-layout-fixed;

	.dropdown-menu {
		a {
			line-height: 48px;
			margin     : 0;
			font-size  : 15px;
			padding    : 0 5px;
		}
	}

}


.layout2-header {

	@extend .header-area;
	@extend .header-area-2;

	.bar-area {
		display: none !important;
	}

	.sub-menu {
		.dropdown-menu {
			.dropdown-item {
				line-height: 40px !important;
			}

			a {
				line-height: 40px !important;
			}

			.nav-item {
				margin     : 0 18px !important;
				padding    : 0 0 !important;
				color      : #555 !important;
				font-size  : 18px !important;
				line-height: 40px !important;
			}
		}

	}

	#tmc {
		display: none !important;
	}

	@include header-layout-fixed;
}


.layout3-header {
	@extend .header-area-2;




	.bar-area {
		display: none !important;
	}

	.country-flag {
		display: none !important;
	}


	@include header-layout-fixed;

	#tmc2 {
		display: none !important;
	}


	.header-top {
		background-color: #0072FF !important;
	}


}


.layout4-header {
	background-color: transparent !important;


	.header-top {
		display: none;
	}

	.bar-area {
		display: none !important;
	}

	@include header-layout-fixed;

	a {
		color: #79a5d4 !important;
	}

	@extend .header-area;
	@extend .header-area-2;
	@extend .header-area-3;




}

.mr-auto.navbar-nav {
	margin-right: 0 !important;
	margin-left : auto;
}

.header-nav .navigation .navbar .navbar-nav .nav-item a {
	font-size     : 18px;
	padding       : 0 35px;
	text-transform: capitalize;
}

.navbar-light {
	&.navbar-brand {
		img {
			@media #{$xs} {
				width: 100px !important;
			}
		}

	}
}